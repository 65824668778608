import React from 'react';
import { graphql } from 'gatsby';
import CaseLayout from '../components/CaseLayout';

const CompanyCase = ({ data: { datoCmsCompanyCase, allDatoCmsCompanyCase } }) => {
  const relatedPosts = allDatoCmsCompanyCase.edges.map((companyCase) => companyCase.node);
  return <CaseLayout {...datoCmsCompanyCase} relatedPosts={relatedPosts} />;
};

export default CompanyCase;

export const companyCaseQuery = graphql`
  query ($id: String) {
    datoCmsCompanyCase(id: { eq: $id }) {
      title
      date(formatString: "DD.MM.YYYY")
      dateTime: date(formatString: "YYYY-MM-DD")
      shortIntroNode {
        childMarkdownRemark {
          html
        }
      }
      slug
      model {
        name
      }
      contentNode {
        childMarkdownRemark {
          excerpt
          html
          fields {
            readingTime {
              text
            }
          }
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
      company {
        name
        yearFounded
        founders {
          name
        }
        ecospheres {
          label
        }
        markets {
          name
        }
        numberOfEmployees
        fundingUsd
        fundingDkk
        ethnicDiversity
        website
        jobOpenings
        logo {
          fixed(width: 288) {
            ...GatsbyDatoCmsFixed
          }
          format
          url
        }
        meta {
          updatedAt(formatString: "DD.MM.YYYY")
        }
      }
      featuredMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1280, placeholder: BLURRED)
      }
    }
    allDatoCmsCompanyCase(
      limit: 7
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          title
          slug
          ribbonCaption
          ribbonImage: featuredMedia {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 384)
            alt
          }
          contentNode {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          model {
            name
          }
          date(formatString: "DD.MM.YYYY")
          dateTime: date(formatString: "YYYY.MM.DD")
        }
      }
    }
  }
`;
